import styles from './Card.module.scss';

const Card = ({
    children,
    style
}: {
    children: React.ReactNode,
    style?: React.CSSProperties
}) => {
  return (
    <section className={styles.border} style={style}>
        {children}
    </section>
    
  )
}

export default Card