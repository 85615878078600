import { Row, Table } from "react-bootstrap";
import { BacktestReportItem, DrawdownType, FactorModelType, drawdownSchema } from "../../../api/schema";
import { toFixed } from "../../../utils/utils";
import { useModelQuery, useModelReportQuery } from "../../../hooks/useModel";
import { useParams } from "react-router-dom";

export const PerformanceDetails = () => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const report = useModelReportQuery(model?.userId, model?.modelId);
    // const report = useBacktestReportQuery(strategyQuery.data?.userId, strategyQuery.data?..modelId, strategyQuery.data?.backtests[0]["backtest-id"]);

    if (report.data) {
        return (
            <Row>
                <h3>Performance Details</h3>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {
                                ["Metrics", "Strategy", "LongOnly", "LongShort", "LongTheShort", "Universe"].map((option) => (
                                    <th key={option}>{option}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Final-Equity", "Volatility", "Sharpe", "Alpha-Universe", "Alpha-SP500", "Beta-Universe", "Beta-SP500", "CAGR", "Max-Drawdown", "Average-Drawdown", "Win-Rate", "Skewness", "Kurtosis", "VaR95", "CVaR95", "Calmar", "Sterling", "Sortino"].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>{metricTest}</td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                                const value = report.data[key];
                                                let formatedValue = 0;
                                                if (value) {
                                                    formatedValue = toFixed(value[metricTest]);
                                                }
                                                return (
                                                    <td key={key}>{formatedValue}</td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </Row>  
        );
    }

    if (report.error) {
        return (
            <div>
                Error fetching backtest performance!
            </div>
        );
    }

    return (
        <Row>
            <h3>Performance Details</h3>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        {
                            ["Metrics", "Strategy", "LongOnly", "LongShort", "LongTheShort", "Universe"].map((option) => (
                                <th key={option}>{option}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                        {
                            ["Final-Equity", "Volatility", "Sharpe", "Alpha-Universe", "Alpha-SP500", "Beta-Universe", "Beta-SP500", "CAGR", "Max-Drawdown", "Average-Drawdown", "Win-Rate", "Skewness", "Kurtosis", "VaR95", "CVaR95", "Calmar", "Sterling", "Sortino"].map((metric) => {
                                const metricTest = metric as keyof BacktestReportItem;
                                return (
                                    <tr key={metricTest}>
                                        <td>{metricTest}</td>

                                        {
                                            Object.keys(drawdownSchema.shape).map((option: string) => {
                                                const key = option as keyof DrawdownType;
                                               
                                                return (
                                                    <td key={key}></td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
            </Table>
        </Row>
    )   
}
