import { useFactorCumulativeReturnQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react'; 
import { FactorStrategy, StrategyObject } from "../../../api/schema";
import Card from "../../common/Card";
import { Placeholder } from "react-bootstrap";

export const CumulativeReturnChart = ({
  factors,
  onFactorClick
}: {
  factors: FactorStrategy[],
  onFactorClick: (series: string) => void
}) => {
    const strategies = factors.reduce((acc, factor) => {
      if (!acc.get(factor.PK)) {
        acc.set(factor.PK, factor);
      }

      return acc;
    }, new Map<string, StrategyObject>());

    const defaultFactor = factors[0] || {userId: "", PK: "", Factor: ""};
    const cumulativeReturnQuery = useFactorCumulativeReturnQuery(defaultFactor.userId, defaultFactor.PK, factors.map((f) => f.Factor).join(","));
    if (cumulativeReturnQuery.data && factors.length > 0) {
      console.log(Object.keys(cumulativeReturnQuery.data));
      const options = {
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: Object.keys(cumulativeReturnQuery.data).map((f) => {
          return {
            data: Object.keys(cumulativeReturnQuery.data[f]).map((date: any) => [new Date(Number(date)), cumulativeReturnQuery.data[f][date]]),
            type: 'line',
            name: f,
            showSymbol: false,
            hoverAnimation: false,
          }
        }),
        tooltip: {
          confine: true,
          trigger: 'axis',
        }
      };

          return (
            <Card>
              <ReactECharts option={options} notMerge={true} onEvents={{
                        click: async (params: any) => {
                            console.log(params);
                            if (params.seriesName) {
                              onFactorClick(params.seriesName);
                            }
                        }
                    }}/>
            </Card>
          );
    } 

    if(cumulativeReturnQuery.error) {
        console.log(cumulativeReturnQuery.error);
        <Card>
          <div className="my-3" style={{
            height: "300px",
            display: "flex",
          }}>
            <div className="m-auto">
              <h4>Failed to load data</h4>
            </div>
          </div>
        </Card>
    }

    return (
        <Card>
          <div className="my-3">
            <Placeholder as="div" animation="glow" >
                <Placeholder xs={12} style={{
                  height: "300px"
                }}/>
            </Placeholder>
          </div>
        </Card>
    );
}
