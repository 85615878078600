import { getANPHost } from "../utils/envHandler";
import { getCookie } from "../utils/utils";
import { isTokenValid, refreshAuthToken } from "./fetch";

let isTokenRefreshed = false;
let waitUntilRefresh = Promise.resolve();

export const getUserDetails = async () => {
  const isValidToken = isTokenValid();

  if (!isValidToken && !isTokenRefreshed) {
    let res = () => {};
    isTokenRefreshed = true;
    waitUntilRefresh = new Promise((resolve) => {
      res = resolve;
    });
    await refreshAuthToken();
    isTokenRefreshed = false;
    res();
  } else {
    await waitUntilRefresh;
  }
    const response = await fetch(`${getANPHost()}/rest-auth/user/`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${getCookie('accessToken')}`
      }
    });
  
    const data = await response.json();
    return data;
  };
