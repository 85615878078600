import { Spinner } from "react-bootstrap";
import { useBusy } from "../../../hooks/useBusy";

const Overlay = () => {
    const {busy} = useBusy();

    return (
        <>
            {busy && <div style={{
                position: "fixed",
                display: "block",
                width: "100%", 
                height: "100%",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.8)",
                zIndex: "3"
            }}>
                <Spinner animation="border" variant="primary" style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                }} /> 
            </div>}
        </>
    );
};

export default Overlay