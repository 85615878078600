import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

type MessageType = "info" | "error" | "warning";
type Message = {
    message: string;
    details?: string
    id: string;
    type: MessageType
};

const MessageContext = createContext({
  messages: [] as Message[],
  addInfoMessage: (message: string, detail?: string) => {},
  addWarningMessage: (message: string, detail?: string) => {},
  addErrorMessage: (message: string, detail?: string) => {},
});

export const MessageProvider = ({ children }: {
    children: React.ReactNode;
}) => {
    const [messages, setMessages] = useState<Message[]>([]);

    const addErrorMessage = useCallback((m: string, detail?: string) => {
      const newMessage = {
          message: m,
          details: detail,
          id: Math.random().toString(36).substr(2, 9),
          type: "error"
      } as Message;
      setMessages((m: Message[]) => [...m, newMessage]);

    }, []);

    const addInfoMessage = useCallback((m: string, detail?: string) => {
      const newMessage = {
          message: m,
          details: detail,
          id: Math.random().toString(36).substr(2, 9),
          type: "info"
      } as Message;
      setMessages((m: Message[]) => [...m, newMessage]);

    }, []);

    const addWarningMessage = useCallback((m: string, detail?: string) => {
      const newMessage = {
          message: m,
          details: detail,
          id: Math.random().toString(36).substr(2, 9),
          type: "warning"
      } as Message;
      setMessages((m: Message[]) => [...m, newMessage]);

    }, []);


    useEffect(() => {
        if (messages.length !== 0) {
          const ids = messages.map((m) => m.id);
          const timer = setTimeout(() => {
              setMessages((m: Message[]) => m.filter((m) => !ids.includes(m.id)));
          }, 5000);
          return () => clearTimeout(timer);
        }
    }, [messages]);


    const value = useMemo(
        () => ({
            messages,
            addInfoMessage,
            addWarningMessage,
            addErrorMessage
        }),
        [messages, addInfoMessage, addWarningMessage, addErrorMessage]
      );
    
    return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};

export const useMessages = () => {
    const context = useContext(MessageContext);
    if (!context) {
      throw new Error("useMessages must be used within MessageProvider");
    }
    return context;
};