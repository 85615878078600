import ReactECharts from 'echarts-for-react'; 
import { toFixed } from "../../../utils/utils";
import { fetchScore } from '../../../api/api';
import { useCallback } from 'react';
import { Button } from 'react-bootstrap';

const ReportPieChart = ({
  center,
  title,
  shares = {},
  gain = {},
  type,
  revert,
  onDownloadPress
}: {
  center?: number | null;
  title: string;
  shares?: any;
  gain?: any;
  type: "Equity" | "Percent";
  revert?: boolean;
  onDownloadPress?: () => void
}) => {
  const sum = Object.keys(shares).reduce((acc, ticker) => acc + shares[ticker], 0);
  const subtext = type === "Equity" ? `Total ${toFixed(sum)} USD`: `${toFixed(sum * 100)}% of portfolio`; 


  const options = {
    title: {
      text: title,
      subtext: subtext,
      left: 'left'
    },
    toolbox: {
      feature: {
        myTool1: {
          show: true,
            title: "Download complete Score",
            icon: "path://M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z",
            onclick: () => {if (onDownloadPress) {
              onDownloadPress()
            }}
        }
      }
  },
    tooltip: {
      trigger: 'item',
      formatter: (params: any, ticket: string, callback: () => void) => {
        const companyGain = toFixed(gain[params.data.name] * 100 || 0, 2);
        const isPositive = companyGain > 0;
        const arrow = isPositive ? "▲" : "▼";
        const gainColor = isPositive ? "green" : "red";
        const mainPart = type === "Equity" ? revert ? `${params.marker}${params.data.name}: -${params.data.value} USD` : `${params.marker}${params.data.name}: ${params.data.value} USD` : `Portion of portfolio: </br>${params.marker}${params.data.name}: ${params.data.value} %`;
        const gainPart = companyGain !== 0 ? `<span style=\"display:inline-block;margin-right:4px;color:${gainColor} \">${arrow}</span> ${isPositive ? "+" : "-"} ${Math.abs(companyGain)}%` : "";


        return `${mainPart}<br/>${gainPart}`;
      }
    },
    legend: {
      bottom: 0,
    },
    series: [
      {
        name: 'Portion of Portfolio',
        type: 'pie',
        radius: ['40%', '70%'],
        data: Object.keys(shares).map((ticker) => ({value: type === "Equity" ?  revert ? (toFixed(shares[ticker]) * -1) : toFixed(shares[ticker]) : toFixed((shares[ticker] * 100 )), name: ticker})),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          color: '#000',
          fontSize: '20',
          position: 'center',
          formatter: () => {
            return center ? `${(toFixed(center * 100))}%` : "";
          },
        }
      }
    ]
  };
  return (
    <div>
      {/* <Button onClick={() => {if(onDownloadPress) onDownloadPress()}} >Fetch Score</Button> */}
      <ReactECharts option={options} notMerge={true} />
    </div>
  )
}

export default ReportPieChart;