import { FactorStrategy } from "../../../api/schema";
import { useLongShortCumulativeQuery } from "../../../hooks/useFactors"
import ReactECharts from 'echarts-for-react';

const LongShortCumulativeChart = ({
    factor
}: {
    factor: FactorStrategy
}) => {
    const longShortCumulativeQuery = useLongShortCumulativeQuery(factor.userId, factor.PK, factor.Factor);

    if (longShortCumulativeQuery.data) {
        const factorSeries: any = longShortCumulativeQuery.data.map((point: any) => {
            return [point.Date, point.Factor] 
        });
        const universeSeries: any = longShortCumulativeQuery.data.map((point: any) => {
            return [point.Date, point.Universe] 
        });



        const options = {
            xAxis: {
                type: 'time'
              },
            yAxis: {
              type: 'value',
              name: 'Cumulative Return',
            },
            series:         [{
                data: factorSeries,
                type: 'line',
                name: "Factor",
                showSymbol: false,
                hoverAnimation: false,
            }, {
                data: universeSeries,
                type: 'line',
                name: "Universe",
                showSymbol: false,
                hoverAnimation: false,
            
            }],
            tooltip: {
                trigger: 'axis',
            },
          };
        return (
            <div>
                <ReactECharts option={options} />
            </div>
        )
    }

    return (
        <div>
            Loading...
        </div>
    )
}

export default LongShortCumulativeChart