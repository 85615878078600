import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";

const RedirectUrl = ({ url }: {
    url: string;

}) => {
    useEffect(() => {
      window.location.href = url;
    }, [url]);
  
    return <></>
  };

const ProtectedRoute = ({ children }: {
    children: React.ReactElement;
}): React.ReactElement => {
  const { accessToken } = useAuth();
  if (!accessToken) {
    // user is not authenticated
    return <RedirectUrl url="https://test.analyticalplatform.com/" />;
  } else {
    return children;
  }
};

export default ProtectedRoute;