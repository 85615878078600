
import { Toast, ToastContainer } from 'react-bootstrap';
import { useMessages } from '../../../hooks/useMessages';

const MessageTypes = {
  "info": "primary",
  "warning": "warning",
   "error": "danger"
}

const MessageCenter = () => {
    const {messages} = useMessages();
  return (
    <ToastContainer
        className="p-3"
        position="bottom-end"
        style={{ zIndex: 1 }}
    >
      {messages.map((message, index) => (
        <Toast bg={MessageTypes[message.type]} key={index}>
          <Toast.Header closeButton={true}>
              <strong className="me-auto">{message.message}</strong>
          </Toast.Header>
          {message.details && <Toast.Body className="text-white">{message.details}</Toast.Body>}
        </Toast>
      ))}
    </ToastContainer>
  )
}

export default MessageCenter