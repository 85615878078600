
import { MomentumChart } from "../modelConfiguration/MomentumChart"
import { useModelQuery } from "../../../hooks/useModel";
import { useFactorMomentumQuery, useFactorsListQuery, useMeanQuantileQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react';
import { PortfolioConstructionType } from "../../../api/schema";

const ModelCharacteristics = ({
    rankingRule,
    portfolioType,
    direction,
    userId,
    strategyId,
    factor
}: {
    rankingRule: "highest" | "lowest",
    portfolioType: PortfolioConstructionType
    direction: 1 | -1,
  userId?: string,
  strategyId?: string,
  factor?: string
}) => {

  const meanQuantileQuery = useMeanQuantileQuery(userId, strategyId, factor);
   
      if (meanQuantileQuery.data) {
        const high = meanQuantileQuery.data?.Log_Return_Q5;
        const low = meanQuantileQuery.data?.Log_Return_Q1;
        let highLow = rankingRule === "highest" ? high - low : low - high;

        const series = [{
            name: 'High',
            type: 'line',
            data: [0, high],
            lineStyle: {
              type: portfolioType === "LongOnly" ? 'solid' : 'dashed'
            }
          }, {
            name: 'Low',
            type: 'line',
            data: [0, low],
            lineStyle: {
              type: portfolioType === "ShortOnly" ? 'solid' : 'dashed'
            }
          },{
            name: rankingRule === "highest" ? 'High-Low' : 'Low-High',
            type: 'line',
            data: [0, highLow],
            lineStyle: {
              type: portfolioType === "LongShort" ? 'solid' : 'dashed'
            }
          }].sort((a, b) => {
            return b.data[1] - a.data[1];
          });

        const options = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['0', direction]
          },
            yAxis: {
              type: 'value',
              name: 'Cumulative Return',
              min: "minValue",
              max: "maxValue"
            },
            series,
            tooltip: {
              trigger: 'axis'
            },
          };

          console.log(options)

        return (
          <>
              <ReactECharts option={options} notMerge={true} />
          </>
        );
      } 

    return (
        <div>
            Loading...
        </div>
    );
      
    }

export default ModelCharacteristics