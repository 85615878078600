import { Breadcrumb, Button, Col, FloatingLabel, Form, Modal, Row, Spinner, Stack, Table } from "react-bootstrap"
import { FactorModelType, FactorStrategy } from "../../../api/schema"
import { useCallback, useEffect, useState } from "react";
import Card from "../../common/Card";
import { useFactorDetailsQuery } from "../../../hooks/useFactors";
import { useMutation, useQueryClient } from "react-query";
import { recalculateModel, updateModel } from "../../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import LongShortCumulativeChart from "./LongShortCumulativeChart";
import { useBenchmarksQuery, useUniverseQuery } from "../../../hooks/useUniverse";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import OverwriteModelDialog from "./dialogs/OverwriteModelDialog";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";
import { useBusy } from "../../../hooks/useBusy";
import { set } from "zod";
import { useMessages } from "../../../hooks/useMessages";
import { padNumber, toFixed } from "../../../utils/utils";
import { useModelQuery } from "../../../hooks/useModel";
import ChooseModelDialog from "./dialogs/ChooseModelDialog";

const ModelAttributes = ({
    factor,
    currentModelName
}: {
    factor: FactorStrategy,
    currentModelName?: string
}) => {
    const factorsDetailsQuery = useFactorDetailsQuery();
    const benchmarksQuery = useBenchmarksQuery();
    const {setIsBusy} = useBusy();
    const [show, setShow] = useState(false);
    const [showChooseModelDialog, setShowChooseModelDialog] = useState(false);
    const [showError, setShowError] = useState(false);
    const [availableModels, setAvailableModels] = useState<FactorModelType[]>([]);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { modelId } = useParams();
    const modelQuery = useModelQuery(modelId || "");
    const model = modelQuery.data;
    const universeQuery = useUniverseQuery(factor.poolName);
    const currentFactor = Object.keys(model?.factor || {})[0];
    
    const poolName = benchmarksQuery.data ? Object.values(benchmarksQuery.data.results).find((b) => b.universe === factor.poolName)?.name : factor.poolName;
    const handleConfirmationDialogClose = useCallback(() => {
        setShow(false);
    }, []);
    const changeModel = useMutation({
        mutationFn: async ({
            factorName, 
            strategyId,
            choosenModel
        }: {
            factorName: string, strategyId: string, choosenModel?: FactorModelType
        }) => {
            debugger;
            handleConfirmationDialogClose();
            setIsBusy(true);
            const updatedModel = await updateModel(model?.userId || choosenModel?.userId || "", modelId || choosenModel?.modelId || "", factorName);
            await recalculateModel(updatedModel.userId, updatedModel.modelId, strategyId, {
                ascending: true
            });
            setIsBusy(false);
            return updatedModel;
        },
        onSuccess: (data: FactorModelType) => {
            addInfoMessage("Portfolio updated", "Factor model of your portfolio has been successfully updated");
            queryClient.invalidateQueries({ queryKey: ['models'] });
            navigate(`/${data.modelId}`);
        },
        onError: (error: any) => {
            setIsBusy(false);
            if (error.$metadata.httpStatusCode === 403) {
                setShowError(true);
                const availableModels = JSON.parse(JSON.parse(error.response.body)?.models);
                setAvailableModels(availableModels);
            } else {
                addErrorMessage("Error", "Your portfolio has not been updated. Please try again later");
            }
        }
    });
    const {addInfoMessage, addErrorMessage} = useMessages();
    const handleChooseModelDialogClose = useCallback((model?: FactorModelType) => {
        setShowChooseModelDialog(false);
        if (model) {
            changeModel.mutate({
                factorName: factor.Factor, 
                strategyId: factor.PK,
                choosenModel: model
            });
        }
    }, [changeModel, factor.Factor, factor.PK]);
    const handleOverwriteDialogClose = useCallback(() => {
        setShowError(false);
        setAvailableModels([]);
        setIsBusy(false);
    }, [setIsBusy]);


    const onClick = () => {
        if (currentFactor) {
            setShow(true)
        } else if (!modelId) {
            setShowChooseModelDialog(true);
        } else {
            changeModel.mutate({
                factorName: factor.Factor, 
                strategyId: factor.PK
            });
        }
    };

    const updateExistingModel = async (model: FactorModelType,factor: string,  strategy: string) => {
        handleConfirmationDialogClose();
        setIsBusy(true);
        const updatedModel = await updateModel(model?.userId || "", modelId || "", factor);
        await recalculateModel(updatedModel.userId, updatedModel.modelId, strategy);
        setIsBusy(false);
        return updatedModel;
    };

    const chooseModelToUpdate = () => {

    };
    


    if (factorsDetailsQuery.data) {
        const factorDetail = factorsDetailsQuery.data.find((f) => f.Feature === factor.Factor);
        return (
            <>
                <Stack direction="horizontal">
                    <div>
                        {`${poolName} / ${factorDetail?.Bucket} / ${factorDetail?.Feature}`}
                    </div>
                    <div className="ms-auto">
                        <Button variant="primary" onClick={() => onClick()}>Use this model</Button>
                    </div>
                </Stack>
                <h3>{factorDetail?.Feature}</h3>
                    <Row>
                        <Col md={8}>
                            <Card style={{
                                height: "100%"
                            }}>
                                <LongShortCumulativeChart factor={factor} />
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <div>
                                    <h5>Stats</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Signal strength</td>
                                                <td>{padNumber(toFixed(factor["T-Stat"], 4), 4)}</td>
                                            </tr>
                                            <tr>
                                                <td>Statistical confidence</td>
                                                <td>{padNumber(toFixed(factor["P-Value"], 4), 4)}</td>
                                            </tr>
                                            <tr>
                                                <td>Signal direction</td>
                                                <td>{factor?.Direction === 1 ? <BsArrowUpRight/> : <BsArrowDownRight/>}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div>
                                    <h5>Factor Info</h5>
                                    {factorDetail?.Description}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={12}>
                            <h4>Model parameters</h4>
                        </Col>
                    </Row>
                    {universeQuery.data && <Form>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Universe"
                                >
                                    <Form.Control type="text" value={factor.poolName} disabled />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Assets in Universe"
                                >
                                    <Form.Control type="text" value={universeQuery.data.assetsCount} disabled />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Number of Quantiles"
                                >
                                    <Form.Control type="text" value={universeQuery.data.numberOfQuantiles} disabled />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Assets in TOP Quantile (Q5)"
                                >
                                    <Form.Control type="text" value={universeQuery.data.topAssets} disabled />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Assets in BOTTOM Quantile (Q1)"
                                >
                                    <Form.Control type="text" value={universeQuery.data.bottomAssets} disabled />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Long-Short Portfolio Constituents"
                                >
                                    <Form.Control type="text" value={`Q5 = ${universeQuery.data.longShortQuantiles.Q5}, Q1 = ${universeQuery.data.longShortQuantiles.Q1}`} disabled />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form>}

                    <ConfirmationDialog 
                        show={show}
                        factor={factor}
                        chosenFactor={currentFactor}
                        model={model}
                        handleClose={handleConfirmationDialogClose}
                        setError={setShowError}
                        setAvailableModels={setAvailableModels}
                    />

                    <OverwriteModelDialog 
                        factor={factor.Factor}
                        show={showError}
                        availableModels={availableModels}
                        handleClose={handleOverwriteDialogClose}
                        direction={factor.Direction}
                    />

                    <ChooseModelDialog 
                        show={showChooseModelDialog}
                        handleClose={handleChooseModelDialogClose}
                    />
            </>)
    }

    return (
        <div>Loading...</div>
    );
}

export default ModelAttributes