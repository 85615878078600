import { useQuery } from "react-query";
import { fetchBenchmarks, fetchUniverse, fetchUniverses } from "../api/api";
import { BenchmarkResponseType, UniverseType } from "../api/schema";

export const useUniverseQuery = <TData = UniverseType>(universeName: string) => useQuery<UniverseType, Error, TData>({
    queryKey: ['universe', universeName],
    queryFn: () => fetchUniverse(universeName),
    enabled: !!universeName
  });

export const useUniversesQuery = <TData = UniverseType[]>() => useQuery<UniverseType[], Error, TData>({
    queryKey: ['universe'],
    queryFn: () => fetchUniverses()
  });

export const useBenchmarksQuery = <TData = BenchmarkResponseType>() => useQuery<BenchmarkResponseType, Error, TData>({
    queryKey: ['benchmarks'],
    queryFn: () => fetchBenchmarks()
  });