import { Stack } from "react-bootstrap";
import ReactECharts from 'echarts-for-react'; 
import { toFixed } from "../../../utils/utils";
import { PortfolioType } from ".";
import { PortfolioConstructionType } from "../../../api/schema";

const Distribution = ({
  portfolioType,
    slider
}: {
  portfolioType: PortfolioConstructionType;
    slider: number;
}) => {
    const data = [...Array(slider)].map((x, i) => {
        return {
            value: toFixed(100/slider, 2),
            name: `${i + 1}`
        };
    
    });
    const options = {
        
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Portfolio share', 
              type: 'pie',
              radius: '50%',
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
    };
  return (
    <Stack style={{
        height: "100%",
        justifyContent: "space-between"
    }}>
        
        {portfolioType !== "ShortOnly" && <div>
            <ReactECharts option={{
                ...options
            }} />
        </div>}
        {portfolioType !== "LongOnly" && <div>
            <ReactECharts option={{
                ...options
            }} />
        </div>}
    </Stack>
  )
}

export default Distribution