import { FactorStrategy } from "../../../../api/schema";
import { useNormalizedMaeOverTimeQuery } from "../../../../hooks/useFactors";
import { toFixed } from "../../../../utils/utils";
import { getOptions } from "./utils"
import ReactECharts from 'echarts-for-react'; 

const NormalizedMAE = ({
    factor
}: {
    factor: FactorStrategy
}) => {
    const modelAccuracyQuery = useNormalizedMaeOverTimeQuery(factor.userId, factor.PK, factor.Factor);

    if (modelAccuracyQuery.data) {
        const options = {
            xAxis: {
              type: 'time'
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                min: function (value :any) {
                  return toFixed(value.min - 0.001, 3);
                },
                max: function (value: any) {
                    console.log("max")
                    console.log(value)
                  return toFixed(value.max + 0.001, 3);
                }
            },
            series: [{
                data: Object.keys(modelAccuracyQuery.data).map((date: any) => [new Date(date), modelAccuracyQuery.data[date]]),
                type: 'line',
                name: "MAE",
                showSymbol: false,
                hoverAnimation: false
              }],
            tooltip: {
              trigger: 'axis',
            },
          };
    return (
        <ReactECharts option={options} />
    )
    }

    return null;
}

export default NormalizedMAE