import { Col } from "react-bootstrap"
import { FactorPerformanceReducedType, FactorPerformanceType } from "../../../api/schema"
import FactorCard from "./FactorCard"

const TopFactors = ({
    factors,
    strategy
}:{
    factors: FactorPerformanceReducedType,
    strategy: string
}) => {
  return (
    <>
        <Col  sm={12} style={{
            alignSelf: "stretch",
            marginBottom: "3rem"
        }}>
            <FactorCard factors={factors} strategy={strategy} />
        </Col>
    </>
  )
}

export default TopFactors