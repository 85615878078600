import { useQuery } from "react-query";
import { factorDetails, factorList, fetchBottomFactors, fetchF3FamaFrench, fetchF5FamaFrench, fetchFactorCumulativeReturn, fetchFactorMomentum, fetchFactorRanking, fetchLongShortCumulative, fetchMeanQuantiles, fetchModelAccuracy, fetchTopFactors } from "../api/api";
import { F3FamaFrenchType, F5FamaFrenchResponseType, F5FamaFrenchType, FactorCumulativeReturnItem, FactorDetailsType, FactorListType, FactorMeanQuantileType, FactorMomentumItem, FactorPerformanceReducedType, FactorPerformanceType, FactorRankingItem, LongShortCumulativeType, ModelAccuracyType } from "../api/schema";

export const useFactoryRankingQuery = <TData = FactorRankingItem[]>(userId?: string, portfolioId?: string, select?: (strategies: FactorRankingItem[]) => TData) => useQuery<FactorRankingItem[], Error, TData>({
    queryKey: ['factorRanking', userId, portfolioId],
    queryFn: () => fetchFactorRanking(userId || "", portfolioId || ""),
    select,
    enabled: !!userId && !!portfolioId
  });

export const useFactorMomentumQuery = <TData = FactorMomentumItem[]>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: FactorMomentumItem[]) => TData) => useQuery<FactorMomentumItem[], Error, TData>({
  queryKey: ['factorMomentum', userId, strategyId, factor],
  queryFn: () => fetchFactorMomentum(userId || "", strategyId || "", factor || ""),
  select,
  enabled: !!userId && !!strategyId && !!factor
});

export const useFactorCumulativeReturnQuery = <TData = FactorCumulativeReturnItem>(userId?: string, strategyId?: string, factors?: string, select?: (strategies: FactorCumulativeReturnItem) => TData) => useQuery<FactorCumulativeReturnItem, Error, TData>({
  queryKey: ['factorCumulativeReturn', userId, strategyId, factors],
  queryFn: () => fetchFactorCumulativeReturn(userId || "", strategyId || "", factors || ""),
  select,
  enabled: !!userId && !!strategyId && !!factors && factors.length > 0
});

export const useFactorsListQuery = <TData = FactorListType[]>(select?: (strategies: FactorListType[]) => TData) => useQuery<FactorListType[], Error, TData>({
  queryKey: ['factorList'],
  queryFn: () => factorList(),
  select
});

export const useFactorDetailsQuery = <TData = FactorDetailsType[]>(select?: (strategies: FactorDetailsType[]) => TData) => useQuery<FactorDetailsType[], Error, TData>({
  queryKey: ['factorDetails'],
  queryFn: () => factorDetails(),
  select
});


export const useF3FamaFrenchQuery = <TData = F3FamaFrenchType>(select?: (data: F3FamaFrenchType) => TData) => useQuery<F3FamaFrenchType, Error, TData>({
  queryKey: ['famafrench3f'],
  queryFn: () => fetchF3FamaFrench(),
  select
});

export const useF5FamaFrenchQuery = <TData = F5FamaFrenchType>(select?: (data: F5FamaFrenchType) => TData) => useQuery<F5FamaFrenchType, Error, TData>({
  queryKey: ['famafrench5f'],
  queryFn: () => fetchF5FamaFrench(),
  select
});

export const useMeanQuantileQuery = <TData = FactorMeanQuantileType>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: FactorMeanQuantileType) => TData) => useQuery<FactorMeanQuantileType, Error, TData>({
  queryKey: ['mean-quantile', strategyId, factor],
  queryFn: () => fetchMeanQuantiles(userId || "", strategyId || "", factor || ""),
  select,
  enabled: !!userId && !!strategyId && !!factor
});

export const useModelAccuracyQuery = <TData = ModelAccuracyType>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: ModelAccuracyType) => TData) => useQuery<ModelAccuracyType, Error, TData>({
  queryKey: ['model-accuracy', strategyId],
  queryFn: () => fetchModelAccuracy(userId || "", strategyId || "", factor || ""  ),
  select,
  enabled: !!userId && !!strategyId && !!factor
});

export const useNormalizedMaeOverTimeQuery = <TData = ModelAccuracyType>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: ModelAccuracyType) => TData) => {
  return useModelAccuracyQuery(userId, strategyId, factor, (data) => data.accuracy_normalized_over_time.MAE);
};

export const useNormalizedR2OverTimeQuery = <TData = ModelAccuracyType>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: ModelAccuracyType) => TData) => {
  return useModelAccuracyQuery(userId, strategyId, factor, (data) => data.accuracy_normalized_over_time.R2);
};


export const useLongShortCumulativeQuery = <TData = LongShortCumulativeType[]>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: LongShortCumulativeType[]) => TData) => useQuery<LongShortCumulativeType[], Error, TData>({
  queryKey: ['longShortCumulative', userId, strategyId, factor],
  queryFn: () => fetchLongShortCumulative(userId || "", strategyId || "", factor || ""),
  select,
  enabled: !!userId && !!strategyId && !!factor
});


export const useTopFactorsQuery = <TData = FactorPerformanceReducedType>(select?: (factorPerformance: FactorPerformanceReducedType) => TData) => useQuery<FactorPerformanceReducedType, Error, TData>({
  queryKey: ['topFactors'],
  queryFn: () => fetchTopFactors(),
  select
});

export const useWorstFactorsQuery = <TData = FactorPerformanceReducedType>(select?: (factorPerformance: FactorPerformanceReducedType) => TData) => useQuery<FactorPerformanceReducedType, Error, TData>({
  queryKey: ['worstFactors'],
  queryFn: () => fetchBottomFactors(),
  select
});