import { useFactorMomentumQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react';
import { FactorStrategy } from "../../../api/schema";

export const MomentumChart = ({
    factor
}: {
    factor: FactorStrategy
}) => {
    const momentumQuery = useFactorMomentumQuery(factor.userId, factor.PK, factor.Factor);
    
    if (momentumQuery.data) {
        if (momentumQuery.data.length === 0) {
            return (
                <>
                    <div>No data for this factor</div>
                </>
            );
        }

        const options = {
            xAxis: {
                type: 'time'
              },
            yAxis: {
              type: 'value',
              name: 'Cumulative Returns'
            },
            series: ["Log_Return_Q1", "Log_Return_Q2", "Log_Return_Q3", "Log_Return_Q4", "Log_Return_Q5"].map((type) => {
            return {
                data: momentumQuery.data?.map((point: any) => [point["Date"], point[type]]),
                type: 'line',
                name: type,
                showSymbol: false,
                hoverAnimation: false
            }
            }),
            tooltip: {
                trigger: 'axis',
            },
          };

          return (
            <>
                <ReactECharts option={options} />
            </>
          );
    } 

    if(momentumQuery.error) {
        console.log(momentumQuery.error);
    }

    return (
        <>
            {/* <Form.Select value={selectedFactor} onChange={(e) => setSelectedFactor(e.target.value)}>
                {query.data?.map((factor) => <option value={factor.Factor}>{factor.Factor}</option>)}
            </Form.Select> */}
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <div>Loading...</div>
        </>
    );
}
