import { Col, Row } from "react-bootstrap"
import BacktestSettingsForm from "./ModelSettingsForm";
import { PerformanceDetails } from "./PerformanceDetails";
import { ModelPerformanceChart } from "./ModelPerformanceChart";
import { DrawdownChart } from "./DrawdownChart";
import PortfolioConstituents from "./PortfolioConstituents";
import { RebalanceReturns } from "./RebalanceReturns";

export const PortfolioDetail = () => {
    return (
      <Row>
          <Col md={8} sm={12}>
            <div className="px-3">
                <ModelPerformanceChart />
                <PortfolioConstituents />
                <DrawdownChart />
                <RebalanceReturns />
                <PerformanceDetails />
            </div>
          </Col>
          <Col md={4}>
              <BacktestSettingsForm/>
          </Col>
      </Row>
    ) 
}
