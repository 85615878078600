import { Col, Row, Stack } from "react-bootstrap"
import { FactorStrategy } from "../../../api/schema"
import { LogReturnsByQuarter } from "./LogReturnsByQuarter"
import { MomentumChart } from "./MomentumChart"
import Card from "../../common/Card"
import NormalizedMAE from "./ErrorAnalysis/NormalizedMAE"
import NormalizedR2 from "./ErrorAnalysis/NormalizedR2"

const FactorOverview = ({
    factor
}: {
    factor: FactorStrategy
}) => {
  return (
    <>
      <Row className="mt-3">
        <Col sm={12}>
        <h4>Returns Analysis</h4>
        </Col>
      </Row>
      <Row>
          <Col sm={6}>
            <Card>
            <MomentumChart factor={factor} />
            </Card>
          </Col>
          <Col sm={6}>
            <Card>
              <LogReturnsByQuarter factor={factor} />
            </Card>
          </Col>
      </Row>
      <Stack className="mt-3">
        <h4>Error Analysis</h4>
      </Stack>
      <Row>
        <Col sm={6} className="mt-3">
            <Card>
              <h4>R2 between predicted returns and real observed returns</h4>
              <NormalizedR2 factor={factor} />
            </Card>
        </Col>
        <Col sm={6} className="mt-3">
            <Card>
              <h4>Mean Absolute Error (MAE) between predicted returns and real observed returns</h4>
              <NormalizedMAE factor={factor} />
            </Card>
        </Col>
        {/* <Col sm={6} className="mt-3">
            <Card>
              <h4>MAE Log</h4>
              <LogMAE factor={factor} />
            </Card>
        </Col>
        <Col sm={6} className="mt-3">
            <Card>
              <h4>MAE R2</h4>
              <LogR2 factor={factor} />
            </Card>
        </Col> */}
      </Row>
    </>
  )
}

export default FactorOverview