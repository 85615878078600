import { useQuery } from "react-query";
import { fetchModelCurrentReturns, fetchModelDrawdown, fetchModelReport, fetchModelReturns, fetchModelTimeseries, fetchModels, fetchScore } from "../api/api";
import { BacktestChartItem, BacktestReportItem, BacktestReportResponse, DrawdownType, FactorModelType, RebalanceReturnsType } from "../api/schema";

export const useModelsQuery = <TData = FactorModelType[]>(select?: (strategies: FactorModelType[]) => TData) => useQuery<FactorModelType[], Error, TData>({
    queryKey: ['models'],
    queryFn: () => fetchModels(),
    select
});

export const useModelQuery = (modelId: string) => useModelsQuery<FactorModelType>((models) => models.find((model) => model.modelId === modelId) as FactorModelType);


export const useModelReportQuery = <TData = BacktestReportResponse>(userId?: string, modelId?: string, select?: (strategies: BacktestReportResponse) => TData) => useQuery<BacktestReportResponse, Error, TData>({
    queryKey: ['model', "table", modelId],
    queryFn: () => fetchModelReport(userId || "", modelId || ""),
    select,
    enabled: !!userId && !!modelId
});

export const useModelChartQuery = <TData = BacktestChartItem[]>(userId?: string, modelId?: string, select?: (strategies: BacktestChartItem[]) => TData) => useQuery<BacktestChartItem[], Error, TData>({
    queryKey: ['model', "chart", modelId],
    queryFn: () => fetchModelTimeseries(userId || "", modelId || ""),
    select,
    enabled: !!userId && !!modelId
});

export const useModelDrawdownQuery = <TData = DrawdownType>(userId?: string, modelId?: string, select?: (strategies: DrawdownType) => TData) => useQuery<DrawdownType, Error, TData>({
    queryKey: ['model', "drawdown", modelId],
    queryFn: () => fetchModelDrawdown(userId || "", modelId || ""),
    select,
    enabled: !!userId && !!modelId
});

export const useModelReturnsQuery = <TData = RebalanceReturnsType>(userId?: string, modelId?: string, select?: (strategies: RebalanceReturnsType) => TData) => useQuery<RebalanceReturnsType, Error, TData>({
    queryKey: ['model', "returns", modelId],
    queryFn: () => fetchModelReturns(userId || "", modelId || ""),
    select,
    enabled: !!userId && !!modelId
});

export const useModelCurrentReturnsQuery = <TData = RebalanceReturnsType>(userId?: string, modelId?: string, select?: (strategies: RebalanceReturnsType) => TData) => useQuery<RebalanceReturnsType, Error, TData>({
    queryKey: ['model', "current-returns", modelId],
    queryFn: () => fetchModelCurrentReturns(userId || "", modelId || ""),
    select,
    enabled: !!userId && !!modelId,
    refetchInterval: 60000
});

export const useDownloadScore = <TData = any>(userId?: string, modelId?: string, date?: string) => useQuery<any, Error, TData>({
    queryKey: ['model', "score", modelId, date],
    queryFn: () => fetchScore(userId || "", modelId || "", date || ""),
    enabled: !!userId && !!modelId && !!date, 
});
