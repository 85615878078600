import { Card } from "react-bootstrap";
import { F3FamaFrenchType } from "../../../api/schema"
import ReactECharts from 'echarts-for-react'; 
import styles from './ModelCard.module.scss';

const FamaFrench3F = ({
    data
}: {
    data: F3FamaFrenchType
}) => {
    const options = {
        legend: {},
        xAxis: {
            type: 'time'
          },
        yAxis: {
          type: 'value'
        },
        series: ["hmlCumulative", "smbCumulative", "mktRfCumulative"].map((factor) => {
            const f = factor as "hmlCumulative" | "smbCumulative" | "mktRfCumulative";
            const map = {
                hmlCumulative: "High Minus Low",
                smbCumulative: "Small Minus Big",
                mktRfCumulative: "Market Premium"
            };
            return {
                name: map[f],
                type: 'line',
                showSymbol: false,
                data: Object.keys(data).map((date: any) => {
                    return [new Date(date), data[date][f]];
                })
            };
        }),
        tooltip: {
            trigger: 'axis',
        },
      };
    return (
        <div className={styles.card} style={{
            height: "100%"
        }}>
            <Card style={{
                height: "inherit",
                display: "flex",
                flexDirection: "column",
                marginBottom: "3rem"
            }} >
                
                <ReactECharts option={options} />
                
            </Card>
        </div>
    );
}

export default FamaFrench3F