import { Col, Placeholder, Row } from "react-bootstrap";
import { useModelsQuery } from "../../../hooks/useModel";
import styles from './Dashboard.module.scss';
import { useF3FamaFrenchQuery, useF5FamaFrenchQuery, useTopFactorsQuery, useWorstFactorsQuery } from "../../../hooks/useFactors";
import UserStrategies from "./UserModels";
import DeliveredStrategies from "./DeliveredModels";
import TopFactors from "./FactorsPerformance";
import FamaFrench3F from "./FamaFrench3F";
import FamaFrench5F from "./FamaFrench5F";

const Dashboard = () => {
    const modelsQuery = useModelsQuery();
    const topFactorsQuery = useTopFactorsQuery();
    const worstFactorsQuery = useWorstFactorsQuery();
    const famaFrench3FQuery = useF3FamaFrenchQuery();
    const famaFrench5FQuery = useF5FamaFrenchQuery();
    
    return (
        <Row>
            <Col sm="12" lg="7">
                <Row>
                    <div className={styles.header}>
                        Factor strategies are designed to assist institutional investors to capture excess returns of systematic factors in a transparent and fully automatic way.
                    </div>
                </Row>
                <Row>
                    <Col sm="12" className="mb-4">
                        <div className={styles.howTo}>
                            <span>
                                To get you started, we have selected a couple of the top-performing factors and a strategies based on them.
                            </span>
                            <span>
                                You can freely:
                                <ol>
                                    <li>Change the Factor model</li>
                                    <li>Built the most suitable Investment strategies for you</li>
                                    <li>Track the performance and see the current stock constitution of your portfolios</li>
                                </ol>
                                 
                            </span>
                        </div>
                    </Col>
                    <Col sm="12" lg="12" className="mb-4">
                        <h3>My strategies and portfolios</h3>
                        <Row className="justify-content-md-center">
                            {modelsQuery.data ? 
                                <UserStrategies key="userStrategy" ownModels={modelsQuery.data}/> :
                                <Placeholder key="userPlaceholder" as="div" animation="glow" >
                                    <Placeholder xs={12} style={{
                                        height: "300px"
                                    }}/>
                                </Placeholder>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col sm="12" lg="5">
                    <Row>
                        <h3>Top 5 gainers of the week</h3>
                    </Row>
                    {topFactorsQuery.data ? <Row>
                            <TopFactors factors={topFactorsQuery.data} strategy={"admin_sp100_M"} />
                        </Row> :
                        <Row>
                            <Placeholder as="div" animation="glow" >
                                <Placeholder xs={12} style={{
                                    height: "300px"
                                }}/>
                            </Placeholder>
                        </Row>
                    }
                    <Row>
                        <h3>Top 5 loosers of the week</h3>
                    </Row>
                    {worstFactorsQuery.data ? <Row>
                            <TopFactors factors={worstFactorsQuery.data} strategy={"admin_sp100_M"} />
                        </Row> :
                        <Row>
                            <Placeholder as="div" animation="glow" >
                                <Placeholder xs={12} style={{
                                    height: "300px"
                                }}/>
                            </Placeholder>
                        </Row>
                    }
                    <Row>
                        <h3>Fama-French 3 Factors</h3>
                    </Row>
                    {famaFrench3FQuery.data ? <Row>
                            <FamaFrench3F data={famaFrench3FQuery.data} />
                        </Row> :
                        <Row>
                            <Placeholder as="div" animation="glow" >
                                <Placeholder xs={12} style={{
                                    height: "300px"
                                }}/>
                            </Placeholder>
                        </Row>
                    }
                    <Row>
                        <h3>Fama-French 5 Factors</h3>
                    </Row>
                    {famaFrench5FQuery.data ? <Row>
                            <FamaFrench5F data={famaFrench5FQuery.data} />
                        </Row> :
                        <Row>
                            <Placeholder as="div" animation="glow" >
                                <Placeholder xs={12} style={{
                                    height: "300px"
                                }}/>
                            </Placeholder>
                        </Row>
                    }
            </Col>
            
        </Row>
    )
}

export default Dashboard