import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap"
import Card from "../../../common/Card"
import Filter from "../../../common/Filter"
import { MouseEvent, useMemo, useState } from "react";
import { FilterOperator } from "../../../common/Filter/Filter";
import FactorsTable from "../FactorsTable";
import { useFactorDetailsQuery, useFactorsListQuery } from "../../../../hooks/useFactors";
import { FactorStrategy } from "../../../../api/schema";
import { CumulativeReturnChart } from "../CumulativeReturnChart";
import styles from './Modal.module.scss';
import { useBenchmarksQuery } from "../../../../hooks/useUniverse";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';


const FactorMimickingDialog = ({
    show,
    onDialogClose
}: {
    show: boolean;
    onDialogClose: (factor?: FactorStrategy) => void;
}) => {
    const benchmarksQuery = useBenchmarksQuery();
    const applyFilter = (value: number, filterValue: number, filterOperator: "<"| ">" | "=") => {
        if (filterOperator === "<") {
            return value < filterValue;
        } else if (filterOperator === ">") {
            return value > filterValue;
        } else {
            return value === filterValue;
        }
    };
    const [search, setSearch] = useState("");
    const [signalDirection, setSignalDirection] = useState("positive");
    const [universe, setUniverse] = useState("sp100");
    const [timeframe, setTimeframe] = useState("1D");
    const [tStat, setTStat] = useState(2);
    const [tStatOperator, setTStatOperator] = useState<FilterOperator>(">");
    const [pValue, setPValue] = useState(0.95);
    const [pValueOperator, setPValueOperator] = useState<FilterOperator>(">");
    const [selectedBucket, setSelectedBucket] = useState<string|null>(null);
    const factorsQuery = useFactorsListQuery();
    const [selectedFactor, setSelectedFactor] = useState<FactorStrategy|null>(null);
    const [shownFactors, setShownFactors] = useState<FactorStrategy[]>([]); 
    const isConfirmationDisabled = !selectedFactor;
    const factorsDetailsQuery = useFactorDetailsQuery();
    const buckets = useMemo(() => {
        const set = new Set<string>();
        return (factorsDetailsQuery.data || []).reduce((acc, val) => {
            acc.add(val.Bucket);
            return acc;
        }, new Set<string>());
    }, [factorsDetailsQuery.data]);
    
    
    const factorBuckets = useMemo(() => {
        const map = new Map<string, string>();
        return (factorsDetailsQuery.data || []).reduce((acc, val) => {
            map.set(val.Feature, val.Bucket);
            return map;
        }, new Map<string, string>()) 
    }, [factorsDetailsQuery.data]);
    
    let universes = useMemo(() => {
        return factorsQuery.data?.reduce((acc, factor) => {
            return acc.add(factor.poolName);
        }, new Set<string>());
    }, [factorsQuery.data]);
    let a = useMemo(() => {
        return factorsQuery.data ? factorsQuery.data
            .filter((f) => f.poolName === universe)
            .reduce((acc: any, stategy) => {
            return [
                ...acc,
                ...stategy.factors.map((f) => {
                    const {factors, ...rest} = stategy;

                    return {
                        ...rest,
                        ...f
                    };
                })
            ]
        }, []) : [];
    }, [factorsQuery.data, universe]);

    const filteredFactors = useMemo(() => {
        return a
        .filter((f: FactorStrategy) => (!search || f.Factor.includes(search) ))
        .filter((f: FactorStrategy) => (!selectedBucket || factorBuckets?.get(f.Factor) === selectedBucket))
        .filter((f: FactorStrategy) => applyFilter(f["P-Value"], pValue || 0, pValueOperator || "="))
        .filter((f: FactorStrategy) => applyFilter(f["T-Stat"], tStat || 0, tStatOperator || "="))
        .filter((f: FactorStrategy) => signalDirection === "positive" ? f.Direction > 0 : f.Direction < 0);
    }, [a, pValue, pValueOperator, tStat, tStatOperator, signalDirection, search, selectedBucket]);

    const onBeforeDialogClose = () => {
        const factor = selectedFactor || undefined;
        setSelectedFactor(null);
        onDialogClose(factor);
    };
    type ValuePiece = Date | null;

    type Value = ValuePiece | [ValuePiece, ValuePiece];
    const [value, onChange] = useState<Value>(new Date());


  return (
    <Modal show={show} onHide={onBeforeDialogClose} dialogClassName={styles.largeModal}>
        <Modal.Header closeButton>
            <Modal.Title>Overwrite existing</Modal.Title>
        </Modal.Header>
        <Row className="m-2">
            <div className="mb-3">
                <Card>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Universe</Form.Label>
                                <Form.Select aria-label="Default select example" value={universe} onChange={(value) => setUniverse(value.currentTarget.value)}>
                                    {universes && Array.from(universes).map((universe: string) => (
                                        <option key={universe}>{benchmarksQuery.data ? Object.values(benchmarksQuery.data.results).find((b) => b.universe === universe)?.name : universe}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Search</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />    
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Category</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={(value) => setSelectedBucket(value.currentTarget.value)}>
                                    <option> </option>
                                    {Array.from(buckets || []).map((bucket) => (
                                        <option key={bucket}>{bucket}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Signal Strength</Form.Label>
                                <Row>
                                    <Filter defaultOperator={tStatOperator} defaultValue={tStat} onChange={(filter) => {
                                        setTStat(filter.value); 
                                        setTStatOperator(filter.operator);
                                    }}/>
                                </Row>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Statistical Confidence</Form.Label>
                                <Row>
                                    <Filter defaultOperator={tStatOperator} defaultValue={pValue} onChange={(filter) => {
                                        setPValue(filter.value); 
                                        setPValueOperator(filter.operator);
                                    }}/>
                                </Row>
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">Signal Direction</Form.Label>
                                <Row>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button variant={signalDirection === "positive" ? "primary" : "secondary"} onClick={() => setSignalDirection("positive")}>Positive Returns</Button>
                                        <Button variant={signalDirection === "negative" ? "primary" : "secondary"} onClick={() => setSignalDirection("negative")}>Inverse Signals</Button>
                                    </ButtonGroup>
                                </Row>
                            </Col>
                        </Row>
                        </Form>
                </Card>
            </div>
            <div>
                {factorsQuery.data && filteredFactors.length > 0 && <div className="mb-3">
                    <CumulativeReturnChart factors={shownFactors} onFactorClick={(factorName: string) => {
                        const factor = filteredFactors.find((f: FactorStrategy) => f.Factor === factorName);
                        if (factor) {
                            setSelectedFactor(factor);
                        }
                    }}/> 
                </div>}
                {factorsQuery.data && <FactorsTable factors={filteredFactors} selectedRow={selectedFactor} onShownFactorsChange={setShownFactors} setSelectedRow={setSelectedFactor}/>}
            </div>
        </Row>
        <Modal.Footer>
            <Button variant="secondary" onClick={onBeforeDialogClose}>
                Cancel
            </Button>
            <Button variant="primary" disabled={isConfirmationDisabled} type="submit" onClick={onBeforeDialogClose}>
                Choose Factor
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default FactorMimickingDialog