import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useDrawdownQuery } from "../../../hooks/useBacktest";
import { DrawdownType, DrawdownTypes, FactorModelType } from "../../../api/schema";
import ReactECharts from 'echarts-for-react'; 
import { useModelDrawdownQuery, useModelQuery } from "../../../hooks/useModel";
import moment from "moment";
import { Placeholder } from "react-bootstrap";

export const DrawdownChart = () => {
    let { modelId = "" } = useParams() ;
    const modelQuery = useModelQuery(modelId);
    const model = modelQuery.data;
    const drawdownQuery = useModelDrawdownQuery(model?.userId, model?.modelId);

    if (drawdownQuery.data && modelQuery.data) {
        const backtestPeriod = moment(modelQuery.data.backtest?.backtestPeriod);
        const type = "Strategy" as DrawdownTypes;
        const dates = Object.keys(drawdownQuery.data[type]).filter((date) => moment(date).isAfter(backtestPeriod))
        const options = {
                    tooltip: {
                      trigger: 'axis'
                    },
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    xAxis: {
                      type: 'time'
                    },
                    yAxis: {
                      type: 'value'
                    },
                    series: [{
                      name: type,
                      type: 'line',
                      data: dates.map((date: any) => [new Date(date), drawdownQuery.data[type][date]]),
                      showSymbol: false,
                      hoverAnimation: false
                    }]
                  };

        return (
            <div className="my-3">
                <h3>Drawdowns</h3>
                <ReactECharts option={options} />
            </div>
        );
    }

    if (drawdownQuery.error) {
        return (
            <div>
                Error fetching performance metrics!
            </div>
        );
    }

    return (
      <div className="my-3">
        <h3>Drawdowns</h3>
        <Placeholder as="div" animation="glow" >
            <Placeholder xs={12} style={{
              height: "300px"
            }}/>
        </Placeholder>
      </div>
    );
}
