
import { date } from 'zod';
import Strategy from '../controls/pages/strategy';
import { apiDeleteCall, apiGetCall, apiPatchCall, apiPostCall } from './fetch';
import { BacktestChartApiResponse, BacktestChartItem, BacktestReportResponse, BenchmarkResponseType, CreateBacktestResponseType, DrawdownType, FactorCumulativeReturnResponse, FactorDetailsType, FactorListResponseType, FactorMeanQuantileResponseType, FactorModelResponseType, FactorModelType, FactorMomentumResponse, FactorRankingItem, LongShortCumulativeResponseType, ModelAccuracyType, NewModel, PerformanceMetricsResponseType, PerformanceTimelineResponseType, RebalanceAPIResponseType, RebalanceObject, RebalanceReturnsResponseType, ReportObject, RollingStatsResponseType, StrategyObject, FactorPerformanceResponseType, UniverseType, backtestChartItemsSchema, backtestReportSchema, benchmarkResponseSchema, drawdownSchema, factorCumulativeReturnSchema, factorDetailsResponseSchema, factorListResponseSchema, factorMeanQuantileResponseSchema, factorModelResponseSchema, factorMomentumSchema, factorRankingSchema, longShortCumulativeResponseSchema, modelAccuracySchema, performanceMetricsResponseSchema, performanceTimelineResponseSchema, rebalanceAPIResponseSchema, rebalanceReturnsResponseSchema, rollingStatsResponseSchema, strategyListSchema, factorResponseSchema, universeSchema, universesSchema, f3FamaFrenchResponseSchema, F3FamaFrenchResponseType, f5FamaFrenchResponseSchema, F5FamaFrenchResponseType, FactorPerformanceReducedType } from './schema';

export const fetchStrategies = async () => {
    const strategies = await apiGetCall('stockpicker', '/strategy');
    const validation = strategyListSchema.safeParse(strategies);
    if (validation.success) {
      return strategies as StrategyObject[];
    } else {
      throw new Error(JSON.stringify(validation.error));
    }
};

export const fetchRebalances = async (portfolioId?: string) => {
  const rebalanceResponse = await apiGetCall('stockpicker', `/rebalance/${portfolioId}`) as RebalanceAPIResponseType;
   const validation = rebalanceAPIResponseSchema.safeParse(rebalanceResponse);

    if (!validation.success) {
        throw new Error(JSON.stringify(validation.error));
    }
    
    
   return rebalanceResponse.results as RebalanceObject| ReportObject[]; 
};

export const fetchBacktestTimeseries = async (userId: string, strategyId: string, backtestId: string) => {
  const backtestChartResponse = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/chart`) as BacktestChartApiResponse;
  const indexes = Object.keys(backtestChartResponse.Date);
  const data = indexes.map((index: string) => {
    return {
      date: backtestChartResponse.Date[index],
      LongOnly: backtestChartResponse.LongOnly[index],
      LongTheShort: backtestChartResponse.LongShort[index],
      LongShort: backtestChartResponse.LongTheShort[index],
      Universe: backtestChartResponse.SP500[index],
      SP100: backtestChartResponse.SP100[index],
      SP500: backtestChartResponse.Universe[index],
    };
  });
  const validation = backtestChartItemsSchema.safeParse(data);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  const backtestChartData = data as BacktestChartItem[];
  return backtestChartData;
};

export const postBacktest = async (userId: string, strategyId: string, backtest: NewModel) => {
  try {
    const backtestResponse = await apiPostCall('stockpicker', `/backtest/${userId}/${strategyId}/create`, backtest);
    return (backtestResponse as CreateBacktestResponseType).results;
  } catch (error) {
    throw new Error("Backtest was not created");
  }
};

export const updatePortfolioTitle = async (portfolioId: string, title: string) => {
  try {
    await apiPatchCall('stockpicker', `/strategies/${portfolioId}`, {
      "strategy-name": title
    });
  } catch (error) {
    throw new Error("Backtest was not created");
  }
};

export const fetchBacktestReport = async (userId?: string, strategyId?: string, backtestId?: string) => {
  const backtestReport = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/table`);
  const validation = backtestReportSchema.safeParse(backtestReport);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return backtestReport as BacktestReportResponse;
};

export const fetchFactorRanking = async (userId: string, portfolioId: string) => {
  const factorRanking = await apiGetCall('stockpicker', `/backtest/${userId}/${portfolioId}/factors`);
  const validation = factorRankingSchema.safeParse(factorRanking);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return factorRanking as FactorRankingItem[]; 
};

export const fetchFactorMomentum = async (userId: string, strategyId: string, factor: string) => {
  const factorRanking = await apiGetCall('stockpicker', `/factor/${userId}/${strategyId}/${factor}/momentum`);
  const validation = factorMomentumSchema.safeParse(factorRanking);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (factorRanking as FactorMomentumResponse).results;
};

export const fetchFactorCumulativeReturn = async (userId: string, strategyId: string, factors: string) => {
  const factorRanking = await apiGetCall('stockpicker', `/factor/${userId}/${strategyId}/cumulative-return?factors=${factors}`);
  const validation = factorCumulativeReturnSchema.safeParse(factorRanking);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (factorRanking as FactorCumulativeReturnResponse).results;
};

export const fetchRebalanceReturns = async (userId: string, strategyId: string, backtestId: string) => {
  const rebalanceReturns = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/returns`) as RebalanceReturnsResponseType;
  
  const validation = rebalanceReturnsResponseSchema.safeParse(rebalanceReturns);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return rebalanceReturns.results;
};

export const fetchRollingStats = async (userId: string, strategyId: string, backtestId: string) => {
  const rollingStatsResponse = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/rolling-stats`);
  const validation = rollingStatsResponseSchema.safeParse(rollingStatsResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (rollingStatsResponse as RollingStatsResponseType).results;
};

export const fetchMeanQuantiles = async (userId: string, strategyId: string, factor: string) => {
  const meanQuantileResponse = await apiGetCall('stockpicker', `/factor/${userId}/${strategyId}/${factor}/mean-quantiles`);
  const validation = factorMeanQuantileResponseSchema.safeParse(meanQuantileResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (meanQuantileResponse as FactorMeanQuantileResponseType).result;
};

export const fetchDrawdown = async (userId: string, strategyId: string, backtestId: string) => {
  const drawdownResponse = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/drawdown`);
  const validation = drawdownSchema.safeParse(drawdownResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return drawdownResponse as DrawdownType;
};

export const fetchPerformanceMetrics = async (userId: string, strategyId: string, backtestId: string) => {
  const performanceMetricsResponse = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/performance-metrics`);
  const validation = performanceMetricsResponseSchema.safeParse(performanceMetricsResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return performanceMetricsResponse as PerformanceMetricsResponseType;
};


export const fetchPerformanceTimeline = async (userId: string, strategyId: string, backtestId: string) => {
  const performanceTimelineResponse = await apiGetCall('stockpicker', `/backtest/${userId}/${strategyId}/${backtestId}/performance-timeline`);
  const validation = performanceTimelineResponseSchema.safeParse(performanceTimelineResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return performanceTimelineResponse as PerformanceTimelineResponseType;
};

export const fetchModelAccuracy = async (userId: string, strategyId: string, factor: string) => {
  const modelAccuracyResponse = await apiGetCall('stockpicker', `/factor/${userId}/${strategyId}/${factor}/model-accuracy`);
  const validation = modelAccuracySchema.safeParse(modelAccuracyResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return modelAccuracyResponse as ModelAccuracyType;
};

export const fetchModels = async () => {
  const modelsResponse = await apiGetCall('stockpicker', `/model`);
  const validation = factorModelResponseSchema.safeParse(modelsResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (modelsResponse as FactorModelResponseType).results;
};

export const createModel = async (name?: string) => {
  try {
    const model = await apiPostCall('stockpicker', "/model", {
      name: name
    }) as unknown as {
      success: boolean;
      url: string;
      data: FactorModelType;
    };
    return model.data;
  } catch (error) {
    throw new Error("Model was not created");
  }
};

export const updateModel = async (userId: string, modelId: string, body: string | FactorModelType) => {
  try {
    const payload = typeof body === "string" ? {
      factor: {
        [body]: 1
      }
    } : body
    const model = await apiPatchCall("stockpicker", `/model/${userId}/${modelId}`, payload) as unknown as {
      success: boolean;
      data: FactorModelType; 
    };
    return model.data;
  } catch (error: any) {
    throw error;
  }
};

export const removeModel = async (userId: string, modelId: string) => {
  try {
    await apiDeleteCall("stockpicker", `/model/${userId}/${modelId}`);
  } catch (error: any) {
    throw error;
  }
};

export const recalculateModel = async (userId: string, modelId: string, strategyId: string, modelData?: NewModel) => {
  try {
    const backtestResponse = await apiPostCall('stockpicker', `/model/${userId}/${modelId}/recalculate`, {
      ...modelData,
      strategyId
    });
    console.log(backtestResponse);
    return backtestResponse;
  } catch (error) {
    throw new Error("Backtest was not created");
  }
};

export const factorList = async () => {
  const modelsResponse = await apiGetCall('stockpicker', `/factor`);
  const validation = factorListResponseSchema.safeParse(modelsResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (modelsResponse as FactorListResponseType).result;
};

export const factorDetails = async () => {
  const modelsResponse = await apiGetCall('stockpicker', `/factor/details`);
  const validation = factorDetailsResponseSchema.safeParse(modelsResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (modelsResponse as FactorDetailsType[]);
};

export const fetchF3FamaFrench = async () => {
  const response = await apiGetCall('stockpicker', `/factor/fama-french/3f`);
  const validation = f3FamaFrenchResponseSchema.safeParse(response);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (response as F3FamaFrenchResponseType).results;
};

export const fetchF5FamaFrench = async () => {
  const response = await apiGetCall('stockpicker', `/factor/fama-french/5f`);
  const validation = f5FamaFrenchResponseSchema.safeParse(response);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (response as F5FamaFrenchResponseType).results;
};


export const fetchLongShortCumulative = async (userId: string, strategyId: string, factor: string) => {
  const longShortCumulative = await apiGetCall('stockpicker', `/factor/${userId}/${strategyId}/${factor}/long-short-cumulative`);
  const validation = longShortCumulativeResponseSchema.safeParse(longShortCumulative);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return (longShortCumulative as LongShortCumulativeResponseType).results;
};

export const fetchModelReport = async (userId?: string, modelId?: string) => {
  const backtestReport = await apiGetCall('stockpicker', `/model/${userId}/${modelId}/table`);
  const validation = backtestReportSchema.safeParse(backtestReport);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return backtestReport as BacktestReportResponse;
};

export const fetchModelTimeseries = async (userId: string, modelId: string) => {
  const backtestChartResponse = await apiGetCall('stockpicker', `/model/${userId}/${modelId}/chart`) as BacktestChartApiResponse;
  const indexes = Object.keys(backtestChartResponse.LongTheShort);
  const data = indexes.map((index: string) => {
    return {
      date: index,
      LongOnly: backtestChartResponse.LongOnly[index],
      ShortOnly: backtestChartResponse.ShortOnly[index],
      LongTheShort: backtestChartResponse.LongTheShort[index],
      LongShort: backtestChartResponse.LongShort[index],
      Universe: backtestChartResponse.SP500[index],
      SP500: backtestChartResponse.Universe[index],
      SP100: backtestChartResponse.SP100[index],
      Strategy: backtestChartResponse.Strategy[index],
    };
  });
  const validation = backtestChartItemsSchema.safeParse(data);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  const backtestChartData = data as BacktestChartItem[];
  return backtestChartData;
};

export const fetchModelDrawdown = async (userId: string, modelId: string) => {
  const drawdownResponse = await apiGetCall('stockpicker', `/model/${userId}/${modelId}/drawdown`);
  const validation = drawdownSchema.safeParse(drawdownResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return drawdownResponse as DrawdownType;
};

export const fetchModelReturns = async (userId: string, strategyId: string) => {
  const rebalanceReturns = await apiGetCall('stockpicker', `/model/${userId}/${strategyId}/returns`) as RebalanceReturnsResponseType;
  
  const validation = rebalanceReturnsResponseSchema.safeParse(rebalanceReturns);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return rebalanceReturns.results;
};

export const fetchModelCurrentReturns = async (userId: string, strategyId: string) => {
  const rebalanceReturns = await apiGetCall('stockpicker', `/model/${userId}/${strategyId}/current-returns`) as RebalanceReturnsResponseType;
  
  const validation = rebalanceReturnsResponseSchema.safeParse(rebalanceReturns);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return rebalanceReturns.results;
};

export const fetchScore = async (userId: string, modelId: string, date: string) => {
  const scoreCSV = await apiGetCall('stockpicker', `/model/${userId}/${modelId}/score/${date}`);
  return scoreCSV;
};

export const fetchUniverses = async () => {
  const universes = await apiGetCall('stockpicker', `/universe`) as UniverseType[];
  
  const validation = universesSchema.safeParse(universes);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return universes;
};

export const fetchUniverse = async (universeName: string) => {
  const universe = await apiGetCall('stockpicker', `/universe/${universeName}`) as UniverseType;
  
  const validation = universeSchema.safeParse(universe);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return universe;
};

export const fetchTopFactors = async () => {
  const topFactorResponse = await apiGetCall('stockpicker', `/factor/admin/top`) as FactorPerformanceResponseType;
  
  const validation = factorResponseSchema.safeParse(topFactorResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return Object.values(topFactorResponse.results).reduce((acc: FactorPerformanceReducedType, item: any) => {
    const performance = acc[item.Strategy] || [];

    return {
      ...acc,
      [item.Strategy]: [...performance, {
        factor: item.Factor,
        performance: item.Value,
        date: new Date(item.Date)
      }]
    };
  }, {} as FactorPerformanceReducedType);
};

export const fetchBottomFactors = async () => {
  const topFactorResponse = await apiGetCall('stockpicker', `/factor/admin/bottom`) as FactorPerformanceResponseType;
  
  const validation = factorResponseSchema.safeParse(topFactorResponse);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return Object.values(topFactorResponse.results).reduce((acc: FactorPerformanceReducedType, item: any) => {
    const performance = acc[item.Strategy] || [];

    return {
      ...acc,
      [item.Strategy]: [...performance, {
        factor: item.Factor,
        performance: item.Value,
        date: new Date(item.Date)
      }]
    };
  }, {} as FactorPerformanceReducedType );
};

export const fetchBenchmarks = async () => {
  const benchmarks = await apiGetCall('stockpicker', `/benchmark`) as BenchmarkResponseType;
  
  const validation = benchmarkResponseSchema.safeParse(benchmarks);
  if (!validation.success) {
      throw new Error(JSON.stringify(validation.error));
  }

  return benchmarks;
};