import '../../App.css';
import '../../assets/styles/index.scss';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../../amplifyconfiguration.json';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { useState } from 'react';
import Overlay from '../common/Overlay';
import MessageCenter from '../common/MessageCenter';

Amplify.configure(amplifyconfig);


function Root({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 30000, // 30 seconds
            retry: 1
          }
        }
      })
  );

  
  return (
    <QueryClientProvider client={queryClient}>
          <div className="App">
            <Overlay />
            <MessageCenter />
            {children}
          </div>
    </QueryClientProvider>
  );
}

export default Root;
