import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useEffect, useState } from "react";
import { useFactoryRankingQuery, useMeanQuantileQuery } from "../../../hooks/useFactors";
import FactorSelector from "../../common/FactorSelector";
// import { useMeanQuantileQuery } from "../../../hooks/useBacktest";
import ReactECharts from 'echarts-for-react'; 
import { FactorStrategy } from "../../../api/schema";

export const LogReturnsByQuarter = ({
  factor
}: {
  factor: FactorStrategy;
}) => {
    const meanQuantileQuery = useMeanQuantileQuery(factor.userId, factor.PK, factor.Factor);
  //   useEffect(() => {
  //     if (query.data ) {
  //         setSelectedFactor(query.data[3].Factor);
  //     }
  
  // }, [query.data]);

  if (meanQuantileQuery.data) {
    const options = {
        xAxis: {
            type: 'category',
            data: ["Q1", "Q2", "Q3", "Q4", "Q5"]
        },
        yAxis: {
          type: 'value',
          name: 'Mean Returns',
        },
        
        series: [
            {
              data: [meanQuantileQuery.data?.Log_Return_Q1, meanQuantileQuery.data?.Log_Return_Q2, meanQuantileQuery.data?.Log_Return_Q3, meanQuantileQuery.data?.Log_Return_Q4, meanQuantileQuery.data?.Log_Return_Q5 ],
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }
          ],
        tooltip: {
          trigger: 'axis'
        },
        
      };

      return (
        <>
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <ReactECharts option={options} />
        </>
      )
  }

  // if (meanQuantileQuery.error) {
  //     return (
  //         <div>
  //             Error fetching log returns by quarter!
  //         </div>
  //     );
  // }

    return (
        <>
            {/* <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} /> */}
            <div>Loading...</div>
        </>
    );
}
