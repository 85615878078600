import Avatar from "react-avatar"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { useAuth } from "../../../hooks/useAuth";

export const User = () => {
  const { user, logout } = useAuth();
  const popover = (
    <Popover id="popover-basic">
        <Popover.Body>
            <div className="p-2">{user?.email}</div>
            <div className="p-2">Settings</div>
            <div className="p-2" onClick={() => logout()}>Logout</div>
        </Popover.Body>
  </Popover>
)
  if (!user?.email) return null;

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Avatar initials={user?.email[0]} round={true} size="2rem" value={user?.email[0]}/>
    </OverlayTrigger>
  )
}
