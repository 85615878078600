import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "./useCookies";
import { getUserDetails } from "../api/analitycalPlatformApi";

export type User = {
  email: string;
  pk: string;
  permissions: string[];
} | null;


const AuthContext = createContext({
  accessToken: "",
  user: null as User,
  logout: () => {}
});

export const AuthProvider = ({ children }: {
    children: React.ReactNode;
}) => {
    const [accessToken, setAccessToken] = useCookies("accessToken", "");
    const [user, setUser] = useState(null);
    const logout = useCallback(() => {
      setAccessToken("");
      setUser(null);
    }, [setAccessToken]);


    useEffect(() => {
        if (accessToken) {
          getUserDetails().then((data) => {
            setUser(data);
          })
        }
    }, [accessToken])

    const value = useMemo(
        () => ({
            accessToken,
            user,
            logout
        }),
        [accessToken, user, logout]
      );
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};