import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap"

export type FilterOperator = "<"| ">" | "=";
export type FilterType = {
    operator: FilterOperator,
    value: number
}

const Filter = ({
    defaultValue,
    defaultOperator,
    onChange
}: {
    defaultValue?: number,
    defaultOperator: FilterOperator,
    onChange: (filter: FilterType) => void
}) => {
    const operators = ["<", ">", "="] as FilterOperator[];
    const [selectedOperator, setSelectedOperator] = useState(defaultOperator || operators[0]);
    const [value, setValue] = useState(defaultValue);

    const changeOperator = (v: string) => {
        const operator = v as FilterOperator;
        setSelectedOperator(operator);
        onChange({operator, value: value || 0});
    };

    const changeValue = (value: number) => {
        setValue(value);
        onChange({operator: selectedOperator, value: value || 0});
    };

  return (
    <InputGroup>
        <Form.Select style={{
            height: "100%",
            marginLeft: "0.5rem",
        }} value={selectedOperator} onChange={(e) => changeOperator(e.target.value)}>
            {operators.map((operator, index) => (
                <option key={index} value={operator}>{operator}</option>
            ))}
        </Form.Select>
        <Form.Control type="number" value={value} onChange={(e) => changeValue(Number(e.target.value))} />
    </InputGroup>
  )
}

export default Filter